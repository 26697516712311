var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"tree-node"},[_c('div',{class:['tree-node__title', {_hoverable: _vm.tree}]},[_c('div',{class:['tree-node__title-wrapper', {'_drop-down': _vm.isSelectable && _vm.tree}],style:(`padding-left: ${_vm.level * 16}px`),on:{"click":_vm.toggleList}},[(_vm.tree && _vm.isSelectable)?_c('svg',{class:['tree-node__arrow', { '_open': _vm.activeList } ],attrs:{"viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M16.59 8.59L12 13.17L7.41 8.59L6 10L12 16L18 10L16.59 8.59Z"}})]):_vm._e(),_c('span',{class:['tree-node__title-text', {'_pl-32': _vm.tree && ((!_vm.data.items && !_vm.data.isCustom) || (_vm.data.isCustom && _vm.data.selectable))}]},[_vm._v(_vm._s(_vm.data.name))])]),((((_vm.data.isCustom && _vm.data.selectable) || !_vm.data.isCustom)))?_c('v-checkbox',{staticClass:"tree-node__checkbox",attrs:{"value":_vm.active},on:{"input":function($event){return _vm.$emit('toggle-checkbox', $event)}}}):_vm._e()],1),(_vm.showList)?_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeList),expression:"activeList"}]},[(_vm.data.items)?_vm._l((_vm.data.items),function(item,i){return _c('v-mixed-tree-node',_vm._b({key:i,on:{"click":function($event){return _vm.click($event)},"open":_vm.open,"toggle-checkbox":function($event){return _vm.updateCatalogIdsFilterFunc($event, item)}}},'v-mixed-tree-node',{
          level: _vm.level + 1,
          data: item,
          value: _vm.value,
          multiple: _vm.multiple,
          active: _vm.getNodeActiveStatus(item),
          filters: _vm.filters,
          updateCatalogIdsFilterFunc: _vm.updateCatalogIdsFilterFunc,
          filterName: _vm.filterName,
          getNodeActiveStatus: _vm.getNodeActiveStatus,
          tree: _vm.tree
        },false))}):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }