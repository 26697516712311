var render = function render(){var _vm=this,_c=_vm._self._c;return _c('s-multiselect',_vm._b({on:{"input":function($event){return _vm.$emit('input', $event)}}},'s-multiselect',{
      id: 'filters_region',
      options: _vm.regions,
      insideLabel: 'Регион',
      placeholder: 'Выберите регион',
      minWidth: '0',
      clearButton: true,
      viewOptionsName: _vm.optionName,
      viewOptionsNameList: _vm.optionName,
      value: _vm.value,
      selectOptionsName: 'google_region_id',
      dynamicWidthDrop: true,
      search: true,
      searchLoad: _vm.searchRegions,
      searchOptions: _vm.regionSearchList,
      minLengthSizeSearch: 3,
      filtrationPlug: 'Введите не менее 3-х букв названия региона',
      appendToBody: false,
      visibleSelectList: true
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }