<template>
  <v-button-group
    :value="value"
    narrow
    new-design
    dataforce
    :options="trafficSources"
    @input="updateUrl({traffic_source: $event})"
  />
</template>

<script>
import updateUrl from '@/mixins/updateUrl'

export default {
  name: 'trafficSourceToggle',

  mixins: [updateUrl],

  props: {
    value: String
  },

  computed: {
    trafficSources() {
      return this.$store.state.trafficSources
    }
  },

  watch: {
    value() {
      this.setTrafficSourceSelectedName()
    }
  },

  mounted() {
    this.setTrafficSourceSelectedName()
  },

  methods: {
    setTrafficSourceSelectedName() {
      const name = this.value && this.value.includes('ym')
        ? 'YM'
        : 'GA'

      this.$store.commit('setTrafficSourceSelectedName', name)
    }
  }
}
</script>
