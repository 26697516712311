<template>
  <s-multiselect
      v-bind="{
        id: 'filters_region',
        options: regions,
        insideLabel: 'Регион',
        placeholder: 'Выберите регион',
        minWidth: '0',
        clearButton: true,
        viewOptionsName: optionName,
        viewOptionsNameList: optionName,
        value,
        selectOptionsName: 'google_region_id',
        dynamicWidthDrop: true,
        search: true,
        searchLoad: searchRegions,
        searchOptions: regionSearchList,
        minLengthSizeSearch: 3,
        filtrationPlug: 'Введите не менее 3-х букв названия региона',
        appendToBody: false,
        visibleSelectList: true
      }"
      @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'filterRegion',

  props: {
    regions: {
      type: Array,
      default: () => ([])
    },

    value: {
      type: [Number, Array]
    }
  },

  data() {
    return {
      optionName: 'en_name'
    }
  },

  computed: {
    regionSearchList () {
      return this.$store.state.regionSearchList.items || []
    }
  },

  methods: {
    searchRegions(data) {
      if (data.action === 'search' && data.search && data.search.length >= 3) {
        return this.$store.dispatch('searchRegions', {
          query: {
            q: data.search
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
