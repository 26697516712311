import ROUTE_NAME from '@/router/routeName'

import {mapGetters, mapActions} from 'vuex'
import mixedTreeNode from './mixedTreeNode.vue'

export default {
  components: {
    'v-mixed-tree-node': mixedTreeNode
  },

  props: {
    /* Значение */
    filters: {
      type: Object,
      default: () => {
        return {}
      }
    },

    /* Внутренний лейбл */
    insideLabel: {
      type: String
    },

    /* Плейсхолдер */
    placeholder: {
      type: String
    },

    /* Множественный выбор */
    multiple: {
      type: Boolean,
      default: true
    },

    /* Значение */
    segmentTypes: {
      type: Array,
      default: () => {
        return ['catalog']
      }
    },

    theme: {
      type: String,
      default: 'default'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    filterName: {
      type: String,
      default: 'catalog_ids'
    },

    needsData: {
      type: Boolean,
      default: true
    },

    dropPosition: {
      type: String
    },

    tree: {
      type: Boolean,
      default: true
    },
  },

  data () {
    return {
      /* Значение фильтра */
      guiValue: [],

      /* Прелоадер */
      preloader: false,

      /* Поле поиска */
      search: ''
    }
  },

  computed: {
    ...mapGetters({
      nestedCatalogSegments: 'segments/catalog/nestedCatalogSegmentsDF'
    }),

    nestedCatalogSegmentsFiltered () {
      const nestedCustomSegments = this.nestedCatalogSegments({
        search: this.search,
        isCustom: true
      })

      const nestedCatalogSegments = this.nestedCatalogSegments({
        search: this.search,
        isCustom: false
      })

      if (nestedCustomSegments && nestedCatalogSegments) {
        return nestedCustomSegments.concat(nestedCatalogSegments)
      }
    },

    /* ID активного проекта в URL */
    activeProjectId () {
      let id = +this.$route.params.activeProject
      return id || undefined
    },

    catalog () {
        const customSegments = this.$store.state.segments.catalog.dataDFCustom
        const catalogSegments = this.$store.state.segments.catalog.dataDF
        const allSegments = JSON.parse(JSON.stringify(customSegments))
        allSegments.items = allSegments.items.concat(catalogSegments.items)

        return allSegments
    }
  },

  async created () {
    if (this.needsData) {
      await this.init()
      await this.$emit('loaded')
    }
  },

  watch: {
    filters () {
      this.setValue(this.filters)
    }
  },

  methods: {
    ...mapActions({
      getTreeCatalog: 'segments/catalog/getTreeCatalog',
      getTreeUserSegments: 'segments/catalog/getTreeUserSegments',
      getCatalog: 'segments/catalog/getCatalog'
    }),

    async init () {
      this.preloader = true
      this.setValue(this.filters)

      const query = {
        project_id: this.activeProjectId,
        skip_regionality: 1
      }

      let requests = [
          this.getTreeCatalog({
            query
          }),
          this.getTreeUserSegments({
            url: '/tree_user_segments',
            query
          }),
          this.getCatalog({query})
      ]

      await Promise.all(requests)

      this.preloader = false
    },

    setValue (filters) {
      this.$set(this, 'guiValue', filters[this.filterName])
    },

    /* Изменение URL фильтров */
    updateFilters (value) {
      this.updateUrl({[this.filterName]: value})
    },

    /* Обновление URL фильтров */
    updateUrl (query) {
      this.$emit('update-url', query)
    },

    updateCatalogIdsFilter (value, segment) {
      const updateQuery = (query, id) => {
        let newQuery = [...query]
        if (value) {
          newQuery.push(id)
        } else {
          let i = newQuery.indexOf(id)
          if (i !== -1) {
            newQuery.splice(i, 1)
          }
        }

        return newQuery
      }

      let catalogIdsQuery

        if (this.multiple) {
          catalogIdsQuery = updateQuery([...this.filters[this.filterName]], segment.id)
        } else {
          catalogIdsQuery = value ? segment.id : undefined
        }

        if (!this.multiple) {
          const popper = this.$refs.selectSegments &&
              this.$refs.selectSegments.$refs.container &&
              this.$refs.selectSegments.$refs.container.$refs.popper
          if (popper) {
            popper.doClose()
            popper.updatePopper()
          }
        }


      this.updateUrl({[this.filterName]: catalogIdsQuery})
    },

    getNodeActiveStatus(item) {
        const filter = this.filters[this.filterName]
        return Array.isArray(filter) ? filter.includes(item.id) : filter === item.id
    }
  }
}
