<template>
  <s-multiselect
      :id="`filters_${filterName}`"
      ref="filterTraffic"
      v-model="guiValue"
      class="filter-traffic"
      :inside-label="filterLabel"
      :placeholder="`Выберите ${titleLowerCase}`"
      max-width="450px"
      dynamic-width-drop
      :options="trafficOptions"
      :append-to-body="false"
      clear-button
      :default-value="defaultValue"
      :on-change="defaultValue ? val => updateFilter(val, true) : undefined"
      @input="deleteItem"
      @open="resizeSelect"
  >
    <template #drop>
      <div class="filter-traffic-drop">
        <div class="filter-traffic__row">
          <div class="filter-traffic__item">
            <div :class="['form__label', {_active: activeSelect.condition}]">Критерий</div>
            <s-select
                v-model="trafficCondition"
                :options="trafficConditions"
                placeholder="Выберите критерий"
                static-width="168px"
                @open="toggleSelectVisibility('condition', true)"
                @close="toggleSelectVisibility('condition', false)"
            />
          </div>

          <div class="filter-traffic__item">
            <div :class="['form__label', {_active: activeSelect.items}]">{{ title }}</div>
            <s-multiselect
                ref="selectOption"
                v-model="selectedItems"
                :options="optionList"
                :placeholder="`Выберите ${titleLowerCase}`"
                :search-load="getList"
                :search-options="filtersTraffic[filterName].search.items"
                search
                clear-button
                search-list-load
                :min-length-size-search="3"
                :filtration-plug="`Введите не менее 3-х букв названия ${searchHintLabel}`"
                visible-select-list
                :append-to-body="false"
                :lazy-load="getList"
                static-width="256px"
                :default-value="defaultValue"
                :on-change="defaultValue ? updateFilter : undefined"
                @open="toggleSelectVisibility('items', true)"
                @close="toggleSelectVisibility('items', false)"
            />
          </div>
        </div>

        <div class="filter-traffic__row _buttons">
          <div class="filter-traffic__item">
            <v-button
                name="Применить"
                padding-right="14px"
                padding-left="14px"
                dataforce
                :disabled="!guiValue.length && !selectedItems.length"
                size="medium"
                @click="applyFilter"
            />
          </div>
          <div class="filter-traffic__item">
            <v-button
                type="text-base"
                name="Отменить"
                padding-right="12px"
                padding-left="12px"
                size="medium"
                @click="closeFilter"
            />
          </div>
        </div>
      </div>
    </template>
  </s-multiselect>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import {findOptions} from '@/function'
import ROUTE_NAME from '@/router/routeName'

import ClickOutside from 'vue-click-outside'
import updateUrl from '@/mixins/updateUrl'

export default {
  name: 'filterTraffic',

  mixins: [updateUrl],

  directives: {
    ClickOutside
  },

  props: {
    title: {
      type: String,
      default: ''
    },

    filterName: {
      type: String,
      required: true
    },

    dateFilters: {
      type: Object,
      required: true
    },

    urlFilters: {
      type: Object,
      default: () => ({})
    },

    needsData: {
      type: Boolean,
      default: true
    },

    urlName: {
      type: String,
      default: ''
    },

    defaultValue: {
      type: String
    }
  },

  data () {
    return {
      trafficCondition: 'contains',

      selectedItems: [],

      guiValue: [],

      activeSelect: {
        condition: false,
        items: false
      },

      trafficOptions: []
    }
  },

  computed: {
    ...mapState({
      trafficConditions: state => state.trafficConditions,
      filtersTraffic: state => state.filtersTraffic
    }),

    activeProjectId () {
      let id = this.$route.params.activeProject
      return id ? +id : undefined
    },

    optionList () {
      let items = []

      if (this.filtersTraffic[this.filterName].items.length) {
        items = [...this.filtersTraffic[this.filterName].items]

        if (this.trafficOptions.length) {
          this.trafficOptions.forEach(item => {
            if (!item.includes(item)) {
              items.splice(0, 0, item)
            }
          })
        }
      } else {
        items = [...this.trafficOptions]
      }

      return items
    },

    titleLowerCase () {
      return this.title.toLowerCase()
    },

    filterLabel () {
      if (this.guiValue.length) {
        const option = findOptions(this.urlFilters[`traffic_conditions[${this.urlFilterName}]`], this.trafficConditions)

        if (option && option.name) {
          return `${this.title} ${option.name.toLowerCase()}`
        } else {
          return this.title
        }
      } else {
        return this.title
      }
    },

    searchHintLabel () {
      switch (this.filterName) {
        case 'source':
          return 'источника'
        case 'medium':
          return 'канала'
        default:
          return ''
      }
    },

    urlFilterName () {
      return this.urlName || this.filterName || ''
    },

    queryEcomm () {
      return this.$route.name === ROUTE_NAME.COMMERCE_ANALYSIS
    },

    isTrafficSourceYM () {
      return this.$route.query.traffic_source === 'ym'
    }
  },

  watch: {
    selectedItems (val) {
      val.forEach(item => {
        if (!this.trafficOptions.includes(item)) {
          this.trafficOptions.push(item)
        }
      })
    },

    guiValue (val) {
      if (!val.length) {
        this.updateUrl({
          [this.urlFilterName]: undefined,
          [`traffic_conditions[${this.urlFilterName}]`]: undefined
        })

        this.selectedItems = []
      }
    },

    isTrafficSourceYM () {
      if (this.needsData) {
        this.getList({
          action: 'lazy',
          firstPage: true
        })
      }
    }
  },

  created () {
    if (this.needsData) {
      this.getList({action: 'lazy'})
    }

    if (this.urlFilters[this.urlFilterName].length) {
      this.selectedItems = [...this.urlFilters[this.urlFilterName]]
      this.guiValue = [...this.urlFilters[this.urlFilterName]]
      this.trafficOptions = [...this.urlFilters[this.urlFilterName]]
    }

    if (this.urlFilters[`traffic_conditions[${this.urlFilterName}]`]) {
      this.trafficCondition = this.urlFilters[`traffic_conditions[${this.urlFilterName}]`]
    }
  },

  updated() {
    this.$nextTick(() => {
      if (this.$refs.filterTraffic) {
        this.$refs.filterTraffic.setLabelWidth()
        this.$refs.filterTraffic.resizeCase()
      }
    })
  },

  destroyed () {
    this.clearFiltersTrafficSearch(this.filterName)
    this.clearFiltersTraffic(this.filterName)
  },

  methods: {
    ...mapMutations([
      'clearFiltersTrafficSearch',
        'clearFiltersTraffic'
    ]),

    toggleSelectVisibility (name, value) {
      this.$set(this.activeSelect, name, value)
    },

    getList (data) {
      if (
          this.activeProjectId && this.filterName && this.dateFilters.from && this.dateFilters.to &&
          ((data.action === 'search' && data.search && data.search.length >= 3) || data.action === 'lazy')
      ) {
        return this.$store.dispatch('getFiltersTraffic', {
          query: {
            project_id: this.activeProjectId,
            dimension: this.filterName,
            q: data.action === 'search' && data.search && data.search.length >= 3 ? data.search : undefined,
            skip_regionality: 1,
            date_from: this.dateFilters.from,
            date_to: this.dateFilters.to,
            place: this.queryEcomm ? 'ecomm' : undefined,
            traffic_source: this.isTrafficSourceYM ? 'ym' : undefined
          },
          firstPage: data.firstPage,
          cancelable: true
        })
      } else {
        return false
      }
    },

    applyFilter () {
      this.guiValue = [...this.selectedItems]
      this.updateUrl({
        [this.urlFilterName]: this.selectedItems,
        [`traffic_conditions[${this.urlFilterName}]`]: this.trafficCondition
      })
      this.$refs.filterTraffic.$refs.container.$refs.popper.doClose()
    },

    closeFilter () {
      this.$refs.filterTraffic.$refs.container.$refs.popper.doClose()
    },

    deleteItem (val) {
      if (!this.defaultValue && val.length) {
        this.updateUrl({[this.urlFilterName]: val})
        this.selectedItems = [...val]
      }
    },

    updateFilter (val, parent = false) {
      const oldValue = parent ? this.guiValue : this.selectedItems
      let newValue = [...val]
      if (oldValue.length === 1 && val.length === 1 && val[0] === this.defaultValue && oldValue[0] === this.defaultValue) {
        return
      } else if (!val.length) {
        newValue = [this.defaultValue]
      }
      if (newValue.length) {
        if (parent) {
          this.updateUrl({[this.urlFilterName]: newValue})
          this.guiValue = [...newValue]
        }
        this.selectedItems = [...newValue]
      }
    },

    resizeSelect () {
      this.$nextTick(() => {
        if (this.$refs.selectOption) {
          this.$refs.selectOption.resizeCase()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.filter-traffic {
  & /deep/ {
    .container-drop {
      width: 500px;
      overflow: unset;
      cursor: default;
    }
  }

  &-drop {
    width: 500px;
  }

  &__row {
    display: flex;
    align-items: flex-end;
    column-gap: 12px;
    padding: 32px;
    border-bottom: 1px solid #E0E6F0;

    &._buttons {
      column-gap: 19px;
    }
  }
}

.form__label {
  margin-bottom: 4px;
  line-height: 16px;
  color: #7D7D7D;
  font-size: 12px;

  &._active {
    color: #2979FF;
  }
}
</style>
