import {NOTIFY_STATUS} from '@/const'

export default {
  computed: {
    regions() {
      return this.$store.getters.filtersRegions()
    },

    regionSearchList() {
      return this.$store.state.regionSearchList
    }
  },

  methods: {
    async getRegions () {
      const region_id = this.$route.query.region_id

      if (region_id) {
        let q
        if (
            Array.isArray(region_id) &&
            !region_id.every(id => this.regions.find(region => String(region.google_region_id) === id))
        ) {
          q = region_id.filter(id => String(id).length >= 3 && !isNaN(+id))
          if (!q.length) {
            q = undefined
          }
        } else if (
            !Array.isArray(region_id) &&
            !this.regions.find(region => String(region.google_region_id) === region_id) &&
            String(region_id).length >= 3 &&
            !isNaN(+region_id)
        ) {
          q = region_id
        }

        if (q) {
          try {
            await this.$store.dispatch(
                'searchRegions',
                {query: {q}}
            )
          } catch (e) {
            this.$notify({
              title: e.message,
              type: NOTIFY_STATUS.ERROR
            })
          }
        }
      }
    },

    getRegionSelected(regionId) {
      if (regionId) {
        let regions = this.regions.filter(region => regionId.includes(region.google_region_id))

        if (regions.length !== regionId.length) {
          this.regionSearchList.items
              .filter(region => regionId.includes(region.google_region_id))
              .forEach(region => {
                if (!regions.find(item => item.google_region_id === region.google_region_id)) {
                  regions.push(region)
                }
              })
        }

        return regions
      } else {
        return undefined
      }
    }
  }
}
